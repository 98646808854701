import {
  Box,
  Button,
  Dialog,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Image from "next/legacy/image";
import { useState } from "react";

import useCommunity from "hooks/useCommunity";
import useIdentity from "hooks/useIdentity";
import { useApp } from "lib/common/appProvider";

export default function MobileDownloadPopover() {
  const { communityCode } = useIdentity();
  const { mobileDownloadUrl } = useApp("site");
  const { community } = useCommunity({ code: communityCode });
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [isDismissed, setIsDismissed] = useState(
    () => localStorage.getItem("mobilePopoverDismissed") === "true"
  );

  const handleDialogClose = () => {
    setIsDismissed(true);
    localStorage.setItem("mobilePopoverDismissed", "true");
  };

  if (!isXs) return null;

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "100%",
          margin: 0,
          position: "fixed",
          bottom: 0,
          padding: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        },
      }}
      open={!isDismissed}
      onClose={handleDialogClose}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={3}
        pb={8}
        width="100%"
        zIndex={100}
      >
        <Typography sx={{ mb: 1 }} textAlign="center" variant="h3">
          Check out our mobile app!
        </Typography>
        <Typography textAlign="center" variant="body2">
          {`All of the best features of ${community?.name} at your fingertips.`}
        </Typography>

        <Box
          display="flex"
          height="185px"
          my={3}
          position="relative"
          width="100%"
        >
          <Image
            alt="Download App"
            layout="fill"
            objectFit="cover"
            src="https://storage.googleapis.com/cco-web-assets/onboarding/mobile-download-popover.jpg"
            style={{
              borderRadius: "10px",
            }}
          />
        </Box>

        <Button
          fullWidth
          href={mobileDownloadUrl}
          target="_blank"
          variant="contained"
        >
          Download now
        </Button>
        <Button
          fullWidth
          sx={{ my: 1 }}
          variant="outlined"
          onClick={handleDialogClose}
        >
          Maybe later
        </Button>
      </Box>
    </Dialog>
  );
}
